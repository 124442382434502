<div [formGroup]="formCompanyService.formGroup">
  <div *ngIf="companyFields['legalForm']?.isAvailable" class="mb-16 multiple-element">
    <dougs-field [field]="companyFields['legalForm']" formControlName="legalForm"></dougs-field>
    <div
      (click)="showLegalFormHistoricContent = !showLegalFormHistoricContent"
      *ngIf="(shouldShowLegalFormDetails$ | async) && !companyFields['legalForm'].isEditable"
      class="detail-container ml-16"
    >
      <p class="tiny color-gray">Détail</p>
      <i *ngIf="showLegalFormHistoricContent" class="fal fa-chevron-up top-right color-gray pl-8"></i>
      <i *ngIf="!showLegalFormHistoricContent" class="fal fa-chevron-down top-right color-gray pl-8"></i>
    </div>
  </div>
  <dougs-company-legal-form
    *ngIf="
      showLegalFormHistoricContent && (shouldShowLegalFormDetails$ | async) && !companyFields['legalForm'].isEditable
    "
    [companyFields]="companyFields"
    [company]="company"
  ></dougs-company-legal-form>
  <dougs-panel-info *ngIf="legalFormChanges" class="legal-form-panel-info px-16 py-24 mb-16">
    <i class="fal fa-info-circle mr-8 my-auto"></i>
    <p class="small">Changement de forme de la société. {{ legalFormChanges }}</p>
  </dougs-panel-info>
  <ng-container *ngIf="companyFields['taxRegime']?.isAvailable">
    <div class="form-layout mb-16">
      <dougs-field [field]="companyFields['taxRegime']" formControlName="taxRegime"></dougs-field>
    </div>
    <div
      (click)="openHistoryValueModal()"
      *ngIf="
        !(
          company.taxRegimes.length === 1 &&
          !(userStateService.loggedInUser$ | async).flags.includes('canAddTaxRegimePeriod')
        )
      "
      class="form-layout mb-16"
    >
      <dougs-button [fullWidth]="true" color="primary-light">
        <i class="fal fa-calendar color-primary mr-8"></i>
        Gérer les périodes
      </dougs-button>
    </div>
  </ng-container>
  <div *ngIf="companyFields['legalName']?.isAvailable" class="form-layout">
    <dougs-field
      [field]="companyFields['legalName']"
      class="mb-16"
      formControlName="legalName"
      [canCopy]="true"
    ></dougs-field>
  </div>
  <div *ngIf="companyFields['brandName']?.isAvailable" class="form-layout mb-16">
    <dougs-field [field]="companyFields['brandName']" formControlName="brandName"></dougs-field>
  </div>
  <div *ngIf="company?.isCreated" class="form-layout mb-16">
    <div>
      <label dougsFormFieldLabel>Logo</label>
      <div class="logo-container mt-8">
        <img *ngIf="company.invoicingLogo" [src]="company.invoicingLogo?.file.url" class="logo mr-16" />
        <dougs-file-input
          (uploadFiles)="companyComponentService.uploadLogo($event, company)"
          [acceptFileType]="'image/png, image/jpeg'"
          [fileInputText]="company.invoicingLogo ? 'Changer le logo' : 'Définir le logo'"
          [multiple]="false"
        ></dougs-file-input>
        <a
          (click)="companyComponentService.deleteLogo($event, company)"
          *ngIf="company.invoicingLogo"
          class="color-error py-4 px-8 logo-delete"
          href=""
        >
          Supprimer le logo
        </a>
      </div>
    </div>
  </div>
  <div *ngIf="companyFields['activity']?.isAvailable" class="form-layout mb-16">
    <dougs-field [field]="companyFields['activity']" formControlName="activity"></dougs-field>
  </div>
  <ng-container *ngIf="companyFields['description']?.isAvailable">
    <div class="form-layout mb-16" id="description">
      <dougs-form-field>
        <label dougsFormFieldLabel>Objet social</label>
        <i
          dougsFormFieldPrefix
          class="fal fa-copy color-admin"
          *ngIf="(userStateService.loggedInUser$ | async)?.isAccountantOrAdmin"
          [dougsCopyToClipboard]="formCompanyService.selectedSocialObject?.label"
        ></i>
        <dougs-select
          #socialObjectIdSelect="ngModel"
          (ngModelChange)="onSocialObjectChange($event)"
          [disabled]="!companyFields['description']?.isEditable"
          [ngModelOptions]="{ standalone: true }"
          [ngModel]="formCompanyService.selectedSocialObject"
          [searchable]="true"
          bindValue="id"
          dougsFormFieldControl
          maxHeight="300"
          placeholder="Sélectionner un objet social"
        >
          <div *ngFor="let group of socialObjectOptionsGroups">
            <dougs-select-option-group [value]="group">
              <dougs-select-option
                *ngFor="let option of socialObjectOptionsGroupedBy[group]; trackBy: trackById"
                [label]="option.label"
                [value]="option"
              >
                <span>
                  <span class="bold">{{ option.label }}</span>
                  <span class="tiny">{{ option.description }}</span>
                </span>
              </dougs-select-option>
            </dougs-select-option-group>
          </div>
        </dougs-select>
      </dougs-form-field>
    </div>
    <div *ngIf="companyFields['description']?.isAvailable" class="form-layout mb-16">
      <dougs-field
        [canCopy]="true"
        (ngModelChange)="onDescriptionChange($event)"
        [field]="companyFields['description']"
        formControlName="description"
      >
      </dougs-field>
    </div>
    <dougs-panel-info
      *ngIf="showAlertRegulations && (userStateService.loggedInUser$ | async).flags.includes('role:legal')"
      class="mb-16"
      type="warning"
    >
      La description de l'activité objet social a été modifiée, veuillez vérifier que la réglementation attenante soit
      conforme à ce nouvel objet social.
    </dougs-panel-info>
    <dougs-panel-info
      *ngIf="regulations && (userStateService.loggedInUser$ | async).flags.includes('role:legal')"
      class="mb-16"
    >
      {{ regulations }}
    </dougs-panel-info>
  </ng-container>
  <dougs-company-activities
    *ngIf="
      !companyStateService.activeCompany.isCreated &&
      (userStateService.loggedInUser$ | async).flags.includes('role:legal')
    "
  />
  <div *ngIf="companyFields['regulatedActivitiesSupportingDocument']?.isAvailable" class="form-layout mb-16">
    <dougs-field
      (deleteFile)="
        fileStateService.deleteFiles(
          company,
          $event,
          'regulatedActivitiesSupportingDocument',
          companyFields['regulatedActivitiesSupportingDocument'].input?.allowMultiple
        )
      "
      (uploadFile)="
        fileStateService.uploadFiles(
          company,
          companyFields['regulatedActivitiesSupportingDocument'].input?.fileType,
          'regulatedActivitiesSupportingDocument',
          $event
        )
      "
      [field]="companyFields['regulatedActivitiesSupportingDocument']"
      formControlName="regulatedActivitiesSupportingDocument"
    ></dougs-field>
  </div>
  <div *ngIf="companyFields['website']?.isAvailable" class="form-layout">
    <dougs-field [field]="companyFields['website']" formControlName="website"></dougs-field>
  </div>
</div>
