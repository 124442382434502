<form (ngSubmit)="form.form.valid && onSubmit()" #form="ngForm">
  <div dougsModalTitle>
    <h6 class="mr-16">Périodes</h6>
    <i dougsModalClose class="fal fa-times"></i>
  </div>
  <div dougsModalContent>
    <div *ngFor="let taxRegime of sortTaxRegimes(data.company.taxRegimes); trackBy: trackByTaxRegime; index as i">
      <dougs-input-datepicker
        [ngModelOptions]="{ standalone: true }"
        [required]="true"
        [disabled]="!data.taxRegimeField.isEditable"
        [(ngModel)]="taxRegime.startDate"
        *ngIf="!taxRegime.isFirst"
        label="Depuis le"
      ></dougs-input-datepicker>
      <div class="form-line">
        <dougs-form-field>
          <label dougsFormFieldLabel>Régime</label>
          <dougs-select
            [ngModelOptions]="{ standalone: true }"
            [required]="true"
            [disabled]="!data.taxRegimeField.isEditable"
            placeholder="Sélectionner une valeur"
            [(ngModel)]="taxRegime.value"
            dougsFormFieldControl
            [maxHeight]="300"
          >
            <dougs-select-option
              *ngFor="let taxRegimeType of data.taxRegimeField.input?.labels.items; trackBy: trackByValue"
              [value]="taxRegimeType.value"
            >
              {{ taxRegimeType.label }}
            </dougs-select-option>
          </dougs-select>
        </dougs-form-field>
      </div>
      <dougs-button
        (click)="removeHistoryValue(taxRegime)"
        type="button"
        class="mb-16"
        color="delete"
        *ngIf="!taxRegime.isFirst && !data.taxRegimeField.isEditable"
      >
        Supprimer la période
      </dougs-button>

      <dougs-divider class="mb-16"></dougs-divider>
    </div>
    <dougs-button type="button" *ngIf="data.taxRegimeField.isEditable" (click)="addHistoryValue()">
      Ajouter une période
    </dougs-button>
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
    <dougs-button type="submit">Valider</dougs-button>
  </div>
</form>
